<template>
    <v-card v-if="showCard" class="rounded-xl pa-5" flat color="#F6F6F9">

        <v-card-title>
            <div class="d-flex align-center">
                <div class="font-weight-bold title">{{ product.name }}</div>
            </div>
        </v-card-title>

        <v-card-text class="text-start pb-0">
            <div class="mt-2 mb-2 font-weight-bold subtitle">{{ product.descriptions[0].short_description }}</div>
            <p class="description" v-html="product.descriptions[0].full_description"></p>
        </v-card-text>

        <v-card-actions >
            <!-- <v-row v-if="isLimitCart && !isSessionSelected && product.typeId != 2900012" class="px-5">
                <span class="text-red text-start">Vous ne pouvez pas choisir cette matière (nombre maximum atteint).</span>
            </v-row> -->
            <v-row v-if="(Object.values(filteredActionsByPeriod(product.actions)).length > 0 && product.type_id != typeCourseFormule) || (product.type_id == typeCourseFormule && product.formule_type == this.typeFormulePlusStage)">
                <v-col cols=12 class="mb-3">
                    <select-field-with-title
                        v-model="selectedPeriod" 
                        label="Période(s)" 
                        class="select-session d-sm-none"
                        :items="getPeriodsCaptions(product)"
                        itemTitle="caption"
                        :returnObject="true"
                        :hide-details="true"
                        @update:modelValue="displayPeriods($event)"
                    ></select-field-with-title>
                    <select-field-with-title
                        v-model="selectedPeriod" 
                        label="Choisir une période" 
                        class="select-session d-none d-sm-block"
                        :items="getPeriodsCaptions(product)"
                        itemTitle="caption"
                        :hide-details="true"
                        :returnObject="true"
                        @update:modelValue="displayPeriods($event)"
                    ></select-field-with-title>
                </v-col>
                <v-row v-if="Object.keys(sessionsLocation).length > 0 && !isSessionSelected" class="pl-1 mb-6">
                    <v-col v-for="(value, location) in sessionsLocation" :key="location" cols="auto" class="py-0 custom-col">
                        <v-checkbox v-model="sessionsLocation[location]" :label="location" hide-details></v-checkbox>
                    </v-col>
                </v-row>
                <v-row v-if="isSessionSelected" class="my-3 ml-7">
                    <span class="text-green">Formation ajoutée à votre sélection pour cette période.</span>
                </v-row>
                <v-row v-else class="px-3 mb-1">
                    <v-col cols=12 v-for="(filteredSession, index) in filteredSessions" :key="index">
                        <v-row class="px-3" justify="space-between" align="center">
                            <div class="d-flex flex-column text-start">
                                <span class="session-label">{{ filteredSession.label }}</span>
                                <span class="session-date">Du {{ formatDate(filteredSession.start_date) }} au {{ formatDate(filteredSession.end_date) }}</span>
                                <span v-if="filteredSession.location && filteredSession.location.description" class="session-location">{{ filteredSession.location.description }}</span>
                            </div>
                            <div v-if="filteredSession.isEntryLevelRequired" class="text-red full-text d-none d-sm-block">Inscriptions clôturées</div>
                            <div v-if="filteredSession.isEntryLevelRequired" class="text-red full-text mt-2 w-100 d-sm-none">Inscriptions clôturées</div>
                            <v-btn v-if="!filteredSession.isEntryLevelRequired" @click="addSessionToCart(filteredSession)" class="font-weight-bold reservation-btn d-none d-sm-block" :disabled="isSessionSelected">
                                R<span class="text-lowercase">éserver</span>
                            </v-btn>
                            <v-btn v-if="!filteredSession.isEntryLevelRequired" @click="addSessionToCart(filteredSession)" class="font-weight-bold reservation-btn mt-2 ml-0 w-100 d-sm-none" :disabled="isSessionSelected">
                                R<span class="text-lowercase">éserver</span>
                            </v-btn>
                        </v-row>
                        <v-divider v-if="index+1 < Object.keys(filteredSessions).length" class="mt-6"></v-divider>
                    </v-col>
                </v-row>
            </v-row>
            <v-row v-if="product.type_id === typeCourseFormule && product.formule_type != this.typeFormulePlusStage && !isSessionSelected">
                <v-col cols=12 v-for="(filteredSession, index) in product.actions" :key="index">
                    <div class="d-none d-sm-block">
                        <v-row class="px-3" justify="space-between" align="center">
                            <v-col cols=8 class="d-flex flex-column text-start">
                                <span class="session-location text-body-2">{{ filteredSession.label }}</span>
                            </v-col>
                            <v-col cols=4 class="py-1">
                                <v-btn @click="addSessionToCart(filteredSession)" size="small" class="font-weight-bold reservation-btn" :disabled="isSessionSelected">
                                    R<span class="text-lowercase">éserver</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="d-sm-none">
                        <v-row class="px-5 mt-1" justify="space-between" align="center">
                            <div class="d-flex flex-column text-start">
                                <span class="session-location text-body-2">{{ filteredSession.label }}</span>
                            </div>
                            <v-btn @click="addSessionToCart(filteredSession)" size="small" class="font-weight-bold reservation-btn mt-2 ml-0 w-100" :disabled="isSessionSelected">
                                R<span class="text-lowercase">éserver</span>
                            </v-btn>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="product.type_id === typeCourseFormule && product.formule_type != this.typeFormulePlusStage && isSessionSelected" class="my-3 ml-7">
                 <span class="text-green">Formation ajoutée à votre sélection.</span>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>

import _groupBy from "lodash/groupBy";
import moment from 'moment';
import { mapGetters } from 'vuex';

import SelectFieldWithTitle from '@/components/form-base/SelectFieldWithTitleBase.vue';

export default {

    components: {
        SelectFieldWithTitle,
    },

    props: {
        isLimitCart: Boolean,
        periodCaptions: Array,
        product: Object
    },

    data() {
        return {
            filteredSessions: null,
            selectedSession: null,
            selectedPeriod: null,
            priorityCodeChoice: 'DATEAUCHOIX',
            typeCourseUnit: 2900019,
            typeCourseFormule: 2900012,
            typeFormulePlusStage: 80001,
            sessions: null,
            sessionsLocation: {}
        };
    },

    computed: {
        ...mapGetters([
            'getCart',
        ]),

        isSessionSelected() {
            var sessionSelected = this.getCart.filter(session => {
                return session.session.product.id == this.product.id
            })

            if(this.product.type_id == this.typeCourseFormule && this.product.formule_id != this.typeFormulePlusStage && sessionSelected.length > 0) {
                return true 
            } else {
                if (
                    this.selectedPeriod !== null && 
                    sessionSelected.some(session => this.selectedPeriod.code.includes(session.session.period))
                ) {
                    return true
                } else {
                    return false
                }
            }
        },

        showCard() {
            var actionsToShow = Object.values(this.product.actions).filter(action => {
                return action.type_id != this.typeCourseUnit
            })
            return actionsToShow.length > 0 ? true : false
        }

    },

    methods: {

        addSessionToCart(session) {
            this.selectedSession = session
            this.selectedSession["product"] = JSON.parse(JSON.stringify(this.product));
            this.$emit('add-to-cart', this.selectedSession)
        },

        displayPeriods(event) {
            this.sessionsLocation = {}
            this.sessions = event.actions
            if(this.sessions.length > 4) {
                this.filterSessions()
            } else {
                this.filteredSessions = this.sessions
            }
        },

        filteredActionsByPeriod(actions) {
            const groupedActions = actions.filter((item) => {
                    return item.period !== undefined
                }
            );
            return _groupBy(groupedActions, "priorityCode");
        },

        filterSessions() {
            this.filteredSessions = this.sessions.filter((action) => {
                return action.location && action.location.description && this.sessionsLocation[action.location.description];
            });
        },

        formatDate(date) {
            return moment(date).format('DD/MM/YYYY');
        },

        getPeriodsCaptions(product) {

            const currentDate = moment();

            const actionsByPriorityAndYear = product.actions.reduce((acc, action) => {
                if(action.type_id != this.typeCourseUnit) {

                    const momentStartDate = moment(action.start_date);

                    if (momentStartDate.isAfter(currentDate)) {
                        let year = momentStartDate.year();
                        if (momentStartDate.isBetween(`${year}-01-01`, `${year}-01-15`, null, '[]')) {
                            year = momentStartDate.subtract(1, 'year').year();
                        }
                        const priorityAndYear = action.period == this.priorityCodeChoice ? action.period : `${action.period} ${year}`
                        if (!acc[priorityAndYear]) {
                            acc[priorityAndYear] = [action];
                        } else {
                            acc[priorityAndYear].push(action);
                        }
                    }
                }
                return acc;
            }, {});

            let sortedKeys = [];

            for (let key in actionsByPriorityAndYear) {
                const firstStartDate = actionsByPriorityAndYear[key][0].start_date;
                const startDate = moment(firstStartDate).toDate();
                const tempObj = {
                    key,
                    startDate,
                };
                sortedKeys.push(tempObj);
            }

            sortedKeys.sort((a, b) => a.startDate - b.startDate);

            let sortedObject= {};
            for (let obj of sortedKeys) {
                sortedObject[obj.key] = actionsByPriorityAndYear[obj.key]
            }

            return Object.keys(sortedObject).map(key => {
                const caption = this.periodCaptions.find(period => key.includes(period.code))
                if (caption === undefined) {
                    return '';
                }
                return {'code': key, 'caption': key.replace(caption.code, caption.caption), 'actions': sortedObject[key] };
            })
        },

    },

    created() {
        var actionsId = this.getCart.map(session => {
            return session.session.id_ammon
        })

        this.product.actions.map(action => {
            if(actionsId.includes(action.id)) {
                this.selectedSession = action;
            }
        })
    },

    watch: {
        sessionsLocation: {
            deep: true,
            handler() {
                if(this.sessions.length > 4) {
                    this.filterSessions()
                }
            },
        },
    },

}

</script>

<style scoped>

p {
    margin-bottom: 10px !important;
}

.title {
    font-size: 18px;
    white-space: normal;
    text-align: start;
}

.subtitle {
    font-size: 14px;
    color: #8184C9;
}

.description {
    font-size: 14px;
    color: #6B6C80;
}

.select-session {
    background-color: white;
}

.session-label {
    font-size: 14px;
    color: #272954;
}

.session-date {
    font-size: 12px;
    color: #272954;
}

.session-location {
    font-size: 12px;
    color: #272954;
}

.reservation-btn {
    background-color:#272954;
    color: white;
    padding-left: 15px!important;
    padding-right: 15px!important;
    font-size: 15px;
}

.full-text {
    padding-left: 15px!important;
    padding-right: 15px!important;
    font-size: 15px;
}

.custom-col {
  height: 40px;
}

</style>