<template>
    <div>
        <!-- Version normale -->
        <v-stepper v-model="currentStep" alt-labels class="d-none d-lg-block">
            <v-stepper-header>
                <template v-for="(item, index) in items" :key="index">
                    <v-divider v-if="index !== 0"></v-divider>
                    <v-stepper-item
                        :title="stepTitles[item.value - 1]"
                        :value="item.value.toString()"
                        :complete="step > item.value"
                    ></v-stepper-item>
                </template>
            </v-stepper-header>
        </v-stepper>

        <!-- Version mobile -->
        <v-stepper v-model="currentStep" vertical class="d-lg-none">
            <v-stepper-header>
                <template v-for="(item, index) in items" :key="index">
                <v-stepper-item
                    :value="item.value.toString()"
                    :complete="step > item.value"
                ></v-stepper-item>
                <v-divider v-if="index !== items.length - 1"></v-divider>
                </template>
            </v-stepper-header>
        </v-stepper>
    </div>
</template>

<script>
export default {

    props: {
        step: {
        type: String,
        required: true,
        },
    },

    data() {
        return {
            items: Array.from({ length: 5 }).map((_, i) => ({
                value: i + 1,
            })),
            stepTitles: [
                'Coordonnées',
                'Choix de la formation',
                'Récapitulatif',
                'Pré-inscription',
                'Paiement',
            ],
            currentStep: this.step,
        };
    },

    watch: {
        step(newStep) {
            this.currentStep = newStep;
        },
    },
};

</script>


<style scoped>

.v-stepper, .v-stepper-header {
    box-shadow: none !important;
    overflow: hidden;
}

</style>