<template>
    <v-card class="rounded-xl pa-7" flat color="#F6F6F9">
        <v-card-title class="mb-5">
            <div class="d-flex align-center">
                <v-icon @click="removeFromCart()" icon="mdi-minus-circle" class="mr-3" size="x-small" color="grey-lighten-1"></v-icon>
                <div class="font-weight-bold title">{{ session.session.product.name }}</div>
            </div>
        </v-card-title>
        <v-card-text class="text-start">
            <v-row>
                <v-col cols=12 class="py-0">
                    <span class="font-weight-medium session-infos">Du {{ formatStartDate(session.session.start_date) }} au {{ formatStartDate(session.session.end_date) }}</span>
                </v-col>
                <v-col cols=12 class="py-0">
                    <span v-if="session.session.location" class="font-weight-medium session-infos">{{ session.session.location.description }} ({{ session.session.location.city }} {{ session.session.location.postcode }})</span>
                </v-col>
                <v-col v-if="session.session.typeId == typeCourseFormule" cols=12 class="py-0 mt-2">
                    <span class="font-weight-medium "><b>Info pratique :</b> Le choix des dates et lieux de stages se fera après l’inscription.</span>
                </v-col>
                <v-col v-if="session.session.typeId != typeCourseFormule" cols=12 class="py-0 mt-2">
                    <span class="font-weight-medium "><b>Info pratique :</b> L'emploi du temps sera accessible sur votre espace personnel.</span>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

import moment from 'moment';

export default {
    
    props: {
        session: Object,
        index: Number
    },

     data() {
        return {
            typeCourseFormule: 1900341,
        }
    },

    methods: {

        formatStartDate(startDate) {
            return moment(startDate).format('DD/MM/YYYY');
        },

        formatEndDate(endDate) {
            return moment(endDate).format('DD/MM/YYYY');
        },

        removeFromCart() {
            this.$store.dispatch('removeFromCart', this.index);
        }

    }
}

</script>

<style scoped>

.title {
    font-size: 20px;
    white-space: normal;
    text-align: start;
}

</style>