<template>
    <v-row justify=center class="mt-4">
        <v-col cols=9>
            <summary-header step=3 title="Récapitulatif"/>
            <summary-Module-list class="mt-12 mb-9"/>
            <!-- <summary-suggestion-list /> -->
            <summary-actions class="mb-5"/>
        </v-col>
    </v-row>
</template>

<script>

import SummaryHeader from '@/components/layout/HeaderBase.vue';
import SummaryActions from '@/components/summary/SummaryActions.vue';
import SummaryModuleList from '@/components/summary/SummaryModuleList.vue';
// import SummarySuggestionList from '@/components/summary/SummarySuggestionList.vue';

export default {
    components: {
        SummaryHeader,
        SummaryActions,
        SummaryModuleList,
        // SummarySuggestionList
    },
};

</script>