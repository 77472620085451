<template>
    <div>
        <div v-for="(childSession, index) in sortedCart" :key="index">
            <div class="text-start font-weight-bold text-primary student">Pour l'élève : {{ index }}</div>
            <summary-module-card 
                v-for="(session, index) in childSession" 
                :key="index"
                class="my-4"
                :session="session"
                :index="session.index"
            />
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';

import SummaryModuleCard from '@/components/summary/SummaryModuleCard.vue';

export default {

    components: {
        SummaryModuleCard
    },

    data() {
        return {
            sortedCart: null,
        }
    },

    computed: {

        ...mapGetters([
            'getCart',
        ]),
        
    },

    methods: {

        sortCart() {
            var cart = this.getCart
            var sortedCart = {}

            cart.map((session, index) => {
                var childName = session.user.infos.firstName + ' ' + session.user.infos.lastName
                if(!(childName in sortedCart)) {
                   sortedCart[childName] = []
                }
                session.index = index
                sortedCart[childName].push(session)
            })
            this.sortedCart = sortedCart
        }

    },

    created() {
        this.sortCart();
    },

    watch: {

        getCart: {
            handler() {
                this.sortCart()
            },
            deep: true
        },

    },

}

</script>


<style scoped>

.student {
    font-size: 20px;
}

</style>