<template>
    <v-row justify=center class="mt-4">
        <v-col cols=9>
            <user-creation-header step=1 title="Vos coordonnées" :alreadyClient="true"/>
            <div class="text-start text-primary font-weight-bold text-h6 mt-13 mb-7">Responsable</div>
            <user-creation-form class="mb-6"/>
        </v-col>
    </v-row>
</template>

<script>

import UserCreationHeader from '@/components/layout/HeaderBase.vue';
import UserCreationForm from '@/components/user-creation/UserCreationForm.vue';

export default {
    components: {
        UserCreationHeader,
        UserCreationForm
    },
};

</script>
