import { createStore } from 'vuex'
import action from './modules/ammon/action'
import cart from './modules/project/cart'
import classe from './modules/ammon/class'
import constant from './modules/ammon/constant'
import datalayer from './modules/project/datalayer'
import info from './modules/project/info'
import inscription from './modules/ammon/inscription'
import payment from './modules/ammon/payment'
import product from './modules/ammon/product'
import suggestion from './modules/ammon/suggestion'
import user from './modules/ammon/user'


const store = createStore({
  modules: {
    action,
    cart,
    classe,
    constant,
    datalayer,
    info,
    inscription,
    payment,
    product,
    suggestion,
    user
  }
})

export default store