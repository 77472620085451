<template>
    <v-row justify=center class="mt-15 mb-10">
        <v-col cols=8>
            <v-card v-if="user" class="rounded-xl py-5 px-10 mr-3 info-panel" flat color="white">
                <v-card-text class="text-start">

                    <div v-if="user">
                        <v-row>
                            <v-col cols=12 class="pb-2">
                                <span class="part-title font-weight-bold">RESPONSABLE</span>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols=12 class="pb-0 pt-2">
                                <span v-if="user.ammon" class="font-weight-bold">{{ user.name }} {{ user.firstname }}</span>
                                <span v-else class="font-weight-bold">{{ user.gender }} {{ user.lastname }} {{ user.firstname }}</span>
                                <v-icon v-if="$route.name == 'ChildCreation'" @click="backToEditUser()" icon="mdi-pencil" color="primary" size="small" class="ml-2 pb-1"></v-icon>
                            </v-col>
                            <v-col cols=12 class="py-0">
                                <span v-if="user.ammon" class="parent-infos">{{ user.ammon.mainAddress.line1}}</span>
                                <span v-else class="parent-infos">{{ user.address }}</span>
                            </v-col>
                            <v-col cols=12 class="pt-0">
                                <span v-if="user.ammon" class="parent-infos">{{ user.ammon.mainAddress.postcode }} {{ user.ammon.mainAddress.city }}</span>
                                <span v-else class="parent-infos">{{ user.postalCode }} {{ user.city }}</span>
                            </v-col>
                        </v-row>
                    </div>
                        
                    <div v-if="user.children">
                        <v-row class="mt-3">
                            <v-col cols=12 class="pb-2">
                                <span class="part-title font-weight-bold">ÉLÈVE(S)</span>
                            </v-col>
                        </v-row>
                        <v-row v-for="child in user.children" :key="child.infos.id" class="mt-0">
                            <v-col cols=12 class="pb-0 pt-2 pb-0">
                                <span class="font-weight-bold">{{ child.infos.lastName }} {{ child.infos.firstName }}</span>
                            </v-col>
                            <v-col cols=12 class="pt-0 pb-3">
                                <span v-if="child.class.results.length > 0" class="child-class">{{ child.class.results[0]['type CLASSE'] }}</span>
                            </v-col>
                        </v-row>
                    </div>

                    <div v-if="Object.keys(sortedCart).length > 0" class="mt-3">
                        <v-row>
                            <v-col cols=12>
                                <span class="part-title font-weight-bold">VOTRE DEMANDE D'INSCRIPTION</span>
                            </v-col>
                        </v-row>
                        <div v-for="(childSession, index) in sortedCart" :key="index" class="mt-3 mb-6">
                            <div class="font-weight-bold text-primary mb-5">Pour {{ index }}</div>
                            <v-row v-for="(session, index) in childSession" :key="index" class="pb-1">
                                <v-col cols=12 class="d-flex justify-space-between py-0">
                                    <div>
                                        <v-icon v-if="$route.name != 'Payment'" @click="removeFromCart(session.index)" icon="mdi-minus-circle" class="mr-1 mb-1" size="x-small" color="grey-lighten-1"></v-icon>
                                        <span class="font-weight-medium">{{ session.session.product.name }}</span>
                                    </div>
                                    <div>
                                        <span class="font-weight-medium">{{ getItemPrice(session, childSession) }}€</span>
                                    </div>
                                </v-col>
                                <v-col cols=12 class="py-0">
                                </v-col>
                                <v-col cols=12 class="py-0">
                                    <span class="session-infos">Du {{ formatStartDate(session.session.start_date) }} au {{ formatStartDate(session.session.end_date) }}</span>
                                </v-col>
                                <v-col cols=12 class="py-0">
                                    <span v-if="session.session.location" class="session-infos">{{ session.session.location.description }} ({{ session.session.location.city }} {{ session.session.location.postcode }})</span>
                                </v-col>
                            </v-row>
                        </div>
                        <v-row v-if="totalPrice > 0" justify="space-between" class="px-3 my-5">
                            <span class="font-weight-bold">Total</span>
                            <span class="font-weight-medium">{{ totalPrice }}€</span>
                        </v-row>
                        <v-row class="px-3">
                            <router-link to="summary" v-if="$route.name == 'ModuleInscription'">
                                <v-btn class="font-weight-medium d-none d-lg-block" color="primary">
                                    V<span class="text-lowercase">alider ce choix</span>
                                    <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                                </v-btn>
                                <v-btn class="font-weight-medium d-lg-none" color="primary">
                                    V<span class="text-lowercase">alider</span>
                                    <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                                </v-btn>
                            </router-link>
                            <router-link to="payment" v-if="$route.name == 'Summary'">
                                <v-btn class="font-weight-medium d-none d-lg-block" color="primary">
                                    C<span class="text-lowercase">onfirmer cette inscription</span>
                                    <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                                </v-btn>
                                <v-btn class="font-weight-medium d-lg-none" color="primary">
                                    C<span class="text-lowercase">onfirmer</span>
                                    <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                                </v-btn>
                            </router-link>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>

        <v-dialog v-model="showInfoPanel" class="info-panel-mobile">
            <v-card v-if="user" class="rounded-xl py-3 px-3" flat color="white">
                <v-card-text class="text-start">

                    <div v-if="user">
                        <v-row>
                            <v-col cols=12 class="pb-2">
                                <span class="part-title font-weight-bold">RESPONSABLE</span>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols=12 class="pb-0 pt-2 line-height-mobile">
                                <span v-if="user.ammon" class="font-weight-medium user-name-mobile">{{ user.name }} {{ user.firstname }}</span>
                                <span v-else class="font-weight-medum">{{ user.gender }} {{ user.lastname }} {{ user.firstname }}</span>
                                <v-icon v-if="$route.name == 'ChildCreation'" @click="backToEditUser(); showInfoPanel = false" icon="mdi-pencil" color="primary" size="small" class="ml-2 pb-1"></v-icon>
                            </v-col>
                            <v-col cols=12 class="py-0 line-height-mobile">
                                <span v-if="user.ammon" class="parent-infos">{{ user.ammon.mainAddress.line1}}</span>
                                <span v-else class="parent-infos">{{ user.address }}</span>
                            </v-col>
                            <v-col cols=12 class="pt-0 line-height-mobile">
                                <span v-if="user.ammon" class="parent-infos">{{ user.ammon.mainAddress.postcode }} {{ user.ammon.mainAddress.city }}</span>
                                <span v-else class="parent-infos">{{ user.postalCode }} {{ user.city }}</span>
                            </v-col>
                        </v-row>
                    </div>
                        
                    <div v-if="user.children">
                        <v-row class="mt-3">
                            <v-col cols=12 class="pb-2">
                                <span class="part-title font-weight-bold">ÉLÈVE(S)</span>
                            </v-col>
                        </v-row>
                        <v-row v-for="child in user.children" :key="child.infos.id" class="mt-0">
                            <v-col cols=12 class="pb-0 pt-2 pb-0 line-height-mobile">
                                <span class="font-weight-medium child-name-mobile">{{ child.infos.lastName }} {{ child.infos.firstName }}</span>
                            </v-col>
                            <v-col cols=12 class="pt-0 pb-3 line-height-mobile">
                                <span v-if="child.class.results.length > 0" class="child-class">{{ child.class.results[0]['type CLASSE'] }}</span>
                            </v-col>
                        </v-row>
                    </div>

                    <div v-if="Object.keys(sortedCart).length > 0" class="mt-3">
                        <v-row>
                            <v-col cols=12>
                                <span class="part-title font-weight-bold">VOTRE DEMANDE D'INSCRIPTION</span>
                            </v-col>
                        </v-row>
                        <div v-for="(childSession, index) in sortedCart" :key="index" class="mt-3 mb-6">
                            <div class="font-weight-medium text-primary child-name-mobile mb-5">Pour {{ index }}</div>
                            <v-row v-for="(session, index) in childSession" :key="index" class="pb-1">
                                <v-col cols=12 class="d-flex justify-space-between py-0 line-height-mobile mb-1">
                                    <div>
                                        <v-icon v-if="$route.name != 'Payment'" @click="removeFromCart(session.index)" icon="mdi-minus-circle" class="mr-1 mb-1" size="x-small" color="grey-lighten-1"></v-icon>
                                        <span class="font-weight-normal product-mobile">{{ session.session.product.name }}</span>
                                    </div>
                                    <div>
                                        <span class="font-weight-normal price-mobile">{{ getItemPrice(session, childSession) }}€</span>
                                    </div>
                                </v-col>
                                <v-col cols=12 class="py-0 line-height-mobile">
                                </v-col>
                                <v-col cols=12 class="py-0 line-height-mobile">
                                    <span class="session-infos">Du {{ formatStartDate(session.session.start_date) }} au {{ formatStartDate(session.session.end_date) }}</span>
                                </v-col>
                                <v-col cols=12 class="py-0 line-height-mobile">
                                    <span v-if="session.session.location" class="session-infos">{{ session.session.location.description }} ({{ session.session.location.city }} {{ session.session.location.postcode }})</span>
                                </v-col>
                            </v-row>
                        </div>
                        <v-row v-if="totalPrice > 0" justify="space-between" class="px-3 my-5">
                            <span class="font-weight-normal total-mobile">Total</span>
                            <span class="font-weight-normal price-mobile">{{ totalPrice }}€</span>
                        </v-row>
                        <v-row class="px-3 mb-1">
                            <router-link to="summary" @click="showInfoPanel = false" v-if="$route.name == 'ModuleInscription'">
                                <v-btn class="font-weight-normal d-lg-none" color="primary">
                                    R<span class="text-lowercase">écapitulatif</span>
                                    <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                                </v-btn>
                            </router-link>
                            <router-link to="payment" @click="showInfoPanel = false" v-if="$route.name == 'Summary'">
                                <v-btn class="font-weight-normal d-lg-none" color="primary">
                                    C<span class="text-lowercase">onfirmer</span>
                                    <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                                </v-btn>
                            </router-link>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-row>
</template>

<script>

import { mapGetters } from 'vuex';

import moment from 'moment';

export default {

    data() {
        return {
            showInfoPanel: false,
            sortedCart: null,
            user: null,
        }
    },

    computed: {

        ...mapGetters([
            'getCart',
            'getShowInfoPanel',
            'getUser',
        ]),

        totalPrice() {
            var price = 0;
            Object.keys(this.sortedCart).map(key => {
                this.sortedCart[key].map(session => {
                    price += parseInt(this.getItemPrice(session, this.sortedCart[key]))
                })
            })
            return price
        }
    },

    methods: {

        backToEditUser() {
            this.$router.push({name: 'UserCreation'});
        },

        formatStartDate(startDate) {
            return moment(startDate).format('DD/MM/YYYY');
        },

        formatEndDate(endDate) {
            return moment(endDate).format('DD/MM/YYYY');
        },

        getItemPrice(session, childSession) {

            var getCurrentPrice = {}
            
            session.session.product.prices.map((priceItem) => {
                const startDate = moment(priceItem.start_date).startOf('day');
                const endDate = moment(priceItem.end_date).startOf('day');
                const currentDate = moment().startOf('day');
                const checkDate = currentDate.isBetween(startDate, endDate, null, '[]');

                if (priceItem.end_date && checkDate) {
                    getCurrentPrice[priceItem.type] = parseInt(priceItem.amount_ttc);
                } else if (!priceItem.end_date && (startDate <= currentDate)) {
                    getCurrentPrice[priceItem.type] = parseInt(priceItem.amount_ttc);
                }
            });

            if ('AUTO1' in getCurrentPrice) {
                return getCurrentPrice['AUTO1'];
            }

            var numberItems = childSession.filter(element => {
                if(element.session.product.type_id != 2900012 &&
                   element.session.start_date == session.session.start_date &&
                   element.session.location && session.session.location &&
                   element.session.location.address == session.session.location.address)
                {
                    return element
                }
            }).length

            if (numberItems < 2) {
                return '1MAT' in getCurrentPrice ? getCurrentPrice['1MAT'] : 0
            }
            else if (numberItems == 2) {
                return '2MAT' in getCurrentPrice ? getCurrentPrice['2MAT'] : 0
            }
            else if (numberItems > 2) {
                if ('3MAT' in getCurrentPrice) {
                    return getCurrentPrice['3MAT']
                }
                else if ('2MAT' in getCurrentPrice) {
                    return getCurrentPrice['2MAT']
                } else {
                    return 0
                }
            }

            return 0
        },

        removeFromCart(index) {
            this.$store.dispatch('removeFromCart', index);
        },

        sortCart() {
            var cart = this.getCart
            var sortedCart = {}
            var limitCart = {}

            cart.map((session, index) => {
                var childName = session.user.infos.firstName + ' ' + session.user.infos.lastName
                if(!(childName in sortedCart)) {
                   sortedCart[childName] = []
                }
                session.index = index
                sortedCart[childName].push(session)
                if(!(session.user.infos.id in limitCart)) {
                   limitCart[session.user.infos.id] = 0
                }
                if (session.session.product.type_id != 2900012) {
                    limitCart[session.user.infos.id] += 1
                }
            })
            this.$store.dispatch('updateLimitCart', limitCart);
            this.sortedCart = sortedCart
        }
    },

    created() {
        this.sortCart();
        if(this.getUser) {
            this.user = this.getUser;
        } else if(JSON.parse(localStorage.getItem('parentDatas'))) {
            this.user = JSON.parse(localStorage.getItem('parentDatas'));
        }
    },

    watch: {

        getUser: function(newUser) {
            this.user = newUser;
        },

        getCart: {
            handler() {
                this.sortCart()
            },
            deep: true
        },

        getShowInfoPanel: function(newVal) {
            if(newVal == true) {
                this.showInfoPanel = true
                this.$store.dispatch('unShowInfoPanel');
            }
        }

    },
    
}

</script>

<style scoped>

.info-panel {
    z-index: 10;
}

.part-title {
    color: #8184C9;
    font-size: 14px;
}

.session-infos, .parent-infos, .child-class {
    font-size: 13px;
    color: #6B6C80;
}

.info-panel-mobile {
    max-width: 400px;
}

.user-name-mobile, .child-name-mobile, .product-mobile, .price-mobile, .total-mobile {
    font-size: 14px;
}

.line-height-mobile {
    line-height: 17px;
}

</style>