<template>
    <div>
        <v-row align=center justify=space-between class="d-md-none px-3 mb-5">
            <div class="responsive-header"></div>
            <v-img :src="require('@/assets/logo-cours-thales-white.png')" width="150px" max-width="150px" class="d-md-none logo-white mb-2"></v-img>
            <div class="d-flex align-center step-log">
                <v-icon
                    v-if="isUserLog || step != 1"
                    @click.stop="drawer = !drawer" 
                    icon="mdi-menu"
                    color="white"
                    class="ml-3"
                ></v-icon>
                <v-navigation-drawer v-model="drawer" temporary>
                    <v-list density="compact" nav>
                        <v-list-item @click="showDetails" prepend-icon="mdi-account-details" title="Voir les détails"></v-list-item>
                        <v-list-item v-if="isUserLog" @click="logout" prepend-icon="mdi-logout" title="Déconnexion"></v-list-item>
                    </v-list>
                </v-navigation-drawer>
            </div>
        </v-row>

        <v-row align=center justify=space-between class="px-3">
            <v-img :src="require('@/assets/logo-cours-thales.png')" width="150px" max-width="150px" class="d-none d-md-block"></v-img>
            <div class="d-flex align-center">
                <v-btn v-if="isUserLog" @click="logout" class="font-weight-bold text-primary d-none d-md-block" variant="text">
                    D<span class="text-lowercase">éconnexion</span>
                </v-btn>
            </div>
        </v-row>
        <stepper class="mt-10" :step="step" />
        <v-row align=end class="mt-4 px-3">
            <div class="text-start font-weight-normal title mr-6">{{ title }}</div>
            <router-link v-if="alreadyClient && !addNewChild" to="/login" class="login-link font-weight-bold text-decoration-none">Déjà client ?</router-link>
        </v-row>
                
    </div>
</template>

<script>

import Stepper from '@/components/layout/StepperBase.vue';

export default {

    components: {
        Stepper,
    },

    props: {
        step: String,
        title: String,
        alreadyClient: Boolean,
    },

    data() {
        return {
            drawer: false,
            isUserLog: false,
            addNewChild: false
        }
    },

    methods: {

        logout() {
            this.$store.dispatch('resetCart')
            this.$store.dispatch('logout')
            .then(() => {
                this.$router.push({name: 'UserCreation'})
            })
        },

        showDetails() {
            this.$store.dispatch('showInfoPanel');
        }

    },

    created() {
        this.isUserLog = localStorage.getItem('token') ? true : false
        this.addNewChild = this.$route.query.action === 'add-new-child' ? true : false
    }
}

</script>

<style scoped>

.responsive-header {
    background-image: url(./../../assets/panel-info-background.jpg);
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 80px;
}

.responsive-header::after {
    height: 80px;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #272954;
    opacity: 0.8;
}

.logo-white {
    z-index: 10;
}

.details {
    font-size: 15px;
}

.step-log {
    z-index: 10;
}

.title {
    font-size: 32px;
    color: #757692;
}

.login-link {
    line-height: 38px;
    font-size: 16px;
    color: #DC3545;
}

</style>
