import axios from 'axios'

const state = {
    constants: null
};

const getters = {
    getConstants: state => state.constants,

};

const actions = {

    getConstantByType: async (_, type_code ) => {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_INTRANET_API_URL + "form/constants/type/" + type_code, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => reject(error));
        });
    },

    getConstants: async ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_URL + "ammon/users/constants", {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                commit('setConstants', response.data.medium);
                resolve(response.data.medium);
            })
            .catch((error) => reject(error));
        });
    }
}

const mutations = {
    setConstants: (state, constants) => {
        state.constants = constants;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};